/* Styles généraux pour le conteneur */
#container {
  position: relative;
  margin: auto;
  width: 800px;
  height: 600px;
}

/* Position de 'cells' */
#cells {
  position: absolute;
  top: 0;
  left: 0;
  width: 80%; /* 40% de la largeur */
  height: 100%; /* 100% de la hauteur */
}

/* Position de 'virus' */
#virus {
  position: absolute;
  bottom: 10%;
  left: 30%; /* Virus chevauche légèrement cells, donc positionné à gauche de cells */
  width: 40%; /* 25% de la hauteur de 'cells' */
  height: 40%; /* 25% de la hauteur de 'cells' */
}

/* Position de 'title' */
#title {
  position: absolute;
  top: 0;
  right: -10%;
  width: 60%; /* 60% de la largeur du rectangle */
  height: 80%; /* 80% de la hauteur du rectangle */
}

/* Position de 'login' */
#login {
  position: absolute;
  bottom: 20%;
  right: 10%;
  width: 30%; /* 20% de la largeur du rectangle */
  height: 20%; /* 15% de la hauteur du rectangle */
}
