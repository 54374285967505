.next-turn-button {
  display: block; /* Centrer en tant qu'élément block */
  width: 100%; /* Largeur totale de la page */
  margin: 20px auto; /* Centrer verticalement et ajouter un espace */
  background-color: #1E6F9F; /* Couleur bleue */
  color: white; /* Couleur du texte */
  font-size: 18px; /* Taille du texte */
  font-weight: bold; /* Texte en gras */
  text-transform: uppercase; /* Texte en majuscules */
  padding: 15px 0; /* Espacement intérieur pour une hauteur uniforme */
  border: none; /* Supprimer la bordure */
  border-radius: 0; /* Coins carrés */
  cursor: pointer; /* Curseur de pointeur */
  transition: background-color 0.3s; /* Animation sur le hover */
}

.next-turn-button:hover {
  background-color: #155a78; /* Couleur plus foncée au survol */
}

.next-turn-button:active {
  background-color: #0f4256; /* Couleur encore plus foncée au clic */
}
