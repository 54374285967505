/* Style de la popup */
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  padding: 20px;
  padding-bottom: 40px;
  background-color: white;
  border: 2px solid black;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 300px; /* Ajuste automatiquement la hauteur */
}

.number-popup {
  height: 200px;
}

.infoPopup {
  height: 700px;
  text-align: center;
}

.rapportPopup {
  height: 460px;
  padding-top: 0;
}

.rapportPopup h4 {
  color: #6A92D7;
  line-height: 22px;
  margin: 0;
  font-family: Bungee;
  font-size: 18px;
  font-weight: 200;
}

.report-item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.report-item img {
  height: 50px;
}

.report-item p {
  margin-left: 15px;
  font-size: 18px;
}

.popup-header {
  background-color: #6A92D7; /* Bandeau bleu */
  color: white;
  padding: 15px;
  text-align: center;
  width: 360px;
}

.popup-header h2 {
  margin: 0;
  font-family: Bungee;
  font-size: 24px;
  color: #FFFFFF;
}

.popup-header h3 {
  font-family: Bungee;

  margin: 5px 0;
  font-size: 18px;
}


/* Contenu de la popup */
.popup-content {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}


.report-content {
  align-items: start;
  padding-left: 0;
  padding-right: 0;
}

/* Bouton de fermeture (croix en haut à droite) */
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  border: none;
  background: none;
  color: black;
  cursor: pointer;
  padding: 0;
  z-index: 9999;
  margin: 0;
}

/* Texte dans la popup */
.popup-content p {
  font-size: 16px;
  color: black;
  text-align: left;
}

/* Boutons ovales et bleus */
.action-btn {
  background-color: #6A92D7;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.action-btn:hover {
  background-color: #6A92D7;
}

/* Conteneur des boutons */
.popup-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.action-btn:disabled {
  background-color: #cccccc; /* Couleur grise */
  color: #666666; /* Texte gris foncé */
  cursor: not-allowed; /* Icône pour indiquer un état non cliquable */
  box-shadow: none; /* Suppression des ombres */
  opacity: 0.7; /* Légère transparence pour indiquer l'état désactivé */
}

.number-input {
  margin: 10px;
  width: 80px; /* Largeur */
  height: 30px; /* Hauteur */
  border: 2px solid #6A92D7; /* Bordure bleue */
  border-radius: 20px; /* Coins arrondis */
  background-color: #f9f9f9; /* Fond clair */
  font-size: 16px; /* Taille du texte */
  text-align: center; /* Centrage du texte */
  color: #333333; /* Couleur du texte */
}

.number-input:focus {
  border-color: #0056b3; /* Couleur plus foncée au focus */
  background-color: #ffffff; /* Fond blanc au focus */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Ombre bleue */
}


h2 {
  font-family: 'Arial', sans-serif; /* Police moderne */
  font-size: 20px; /* Taille de police */
  font-weight: bold; /* Texte en gras */
  color: #6A92D7; /* Couleur du texte */
  text-align: center; /* Centrer le texte */
  padding: 10px 20px; /* Espacement interne */
  border-radius: 10px; /* Coins arrondis */
  margin-bottom: 15px; /* Espacement sous le titre */
}
