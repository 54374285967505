/* Style général du header */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #C1D1ED;
  height: 120px;
  position: relative;
  margin-bottom: 10px;
}

/* Conteneurs gauche, central et droit */
.left-container,
.central-container,
.right-container {
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 5000;
}

/* Conteneur d'icônes à gauche */
.left-icons {
  position: absolute;
  z-index: 5000;
}

/* Positionnement des conteneurs */
.left-container {
  left: 10%;
}

.left-icons {
  left: 35%;
}

.central-container {
  right: 25%;
}

.right-container {
  right: 10%;
}

/* Icônes */
.icon {
  width: 80px;
  height: 50px;
  text-align: center;
}

.left-icons img {
  width: 100%;
  height: 100%;
}

/* Images des icônes */
.left-image,
.right-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  position: relative;
  border-radius: 50%;
}

.left-image {
  left: 80px;
}

.right-image {
  right: -80px;
}

/* Conteneur des textes à gauche et à droite */
.left-box,
.right-box {
  background-color: #ffffff;
  border-radius: 30px;
  padding: 10px 15px;
  margin: 0 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100px;
  height: 40px;
}

/* Texte à gauche et à droite */
.left-text,
.right-text {
  margin-left: 50px;
  font-size: 24px;
  font-weight: bold;
  color: #6A92D7;
}

/* Conteneur central */
.center-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

/* Boîte centrale */
.center-box {
  background-color: #6A92D7;
  border-radius: 50px;
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  height: 100px;
}

/* Texte principal dans le rectangle central */
.center-main-text {
  color: white;
  font-weight: bold;
  font-family: 'Bungee';
  font-size: 52px;
  font-weight: bold;
  text-align: center;
  width: 80%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Sous-texte central */
.center-sub-text {
  font-family: 'Bungee';
  font-weight: bold;
  text-transform: uppercase;
  font-size: 18px;
  text-align: center;
  width: 60%;
  height: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -10px;
}

/* Filtre d'icône */
.filter-red {
  filter: invert(26%) sepia(50%) saturate(6635%) hue-rotate(351deg) brightness(89%) contrast(139%);
}

/* Media queries pour rendre le header responsive */

/* Pour les écrans plus petits que 768px */
@media (max-width: 768px) {
  /* Disposition des icônes à gauche */
  .left-container,
  .central-container,
  .right-container {
    position: static;
    margin: 10px 0;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .left-icons {
    position: relative;
    margin-top: 10px;
  }

  .left-container,
  .central-container,
  .right-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  /* Réduction de la taille du texte dans le rectangle central */
  .center-box {
    width: 70%; /* Augmenter la largeur pour occuper plus d'espace */
    height: 80px;
    padding: 10px;
  }

  .center-main-text {
    font-size: 32px; /* Réduire la taille du texte */
    width: 80%;
    height: 70%;
  }

  .center-sub-text {
    font-size: 14px; /* Réduire la taille du sous-texte */
    width: 60%;
    height: 20%;
  }

  /* Réduction de la taille des icônes */
  .left-image,
  .right-image {
    width: 60px;
    height: 60px;
  }

  /* Ajustement du texte dans les boîtes de gauche et droite */
  .left-text,
  .right-text {
    font-size: 18px; /* Réduire la taille du texte */
  }

  /* Ajustement du style des icônes dans le conteneur gauche/droit */
  .icon {
    width: 40px;
    height: 40px;
  }

  /* Espacement et ajustement des marges */
  .left-box,
  .right-box {
    width: 80%;
    height: 30px;
    margin: 10px 0;
  }

  /* Ajustement des marges pour l'image de gauche */
  .left-image {
    left: 50px; /* Réduire le débordement */
  }

  /* Ajustement des marges pour l'image de droite */
  .right-image {
    right: -50px; /* Réduire le débordement */
  }
}

/* Pour les écrans plus petits que 480px */
@media (max-width: 480px) {
  /* Disposition de tous les éléments en colonne */
  .header {
    flex-direction: column;
    height: auto;
  }

  /* Ajustement du rectangle central */
  .center-box {
    width: 90%;
    height: 70px;
  }

  .center-main-text {
    font-size: 24px; /* Taille plus petite */
  }

  .center-sub-text {
    font-size: 12px; /* Taille plus petite */
  }

  /* Ajustement des icônes */
  .left-image,
  .right-image {
    width: 50px;
    height: 50px;
  }

  /* Réduction de la taille du texte des boîtes */
  .left-text,
  .right-text {
    font-size: 16px;
  }

  /* Réduction des marges */
  .left-box,
  .right-box {
    width: 70%;
    height: 30px;
    margin: 10px 0;
  }

  .icon {
    width: 35px;
    height: 35px;
  }
}
