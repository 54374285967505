/* globals.css */
body {
  font-family: "Work Sans", sans-serif;
  padding: 20px;
}

.board {
  width: 1200px;
  margin: auto;
  display: grid;
  place-items: center;
  grid-template-columns: repeat(25, 45px); 
  grid-column-gap: 2px; /* Espacement de 5px entre les cellules */
  grid-row-gap: 1px;
}

.cell {
  width: 45px;
  height: 25px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1px;  /* Pour des coins arrondis */
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

button {
  margin: 10px;
  padding: 10px;
  cursor: pointer;
}

.tooltip {
  background-color: white!important;
  border: 1px solid black;
  color: black!important;
  text-align: center;
  height: 40px;
  z-index: 9999;
}

.tooltip-header {
  font-size: 24px;
  line-height: 40px;
}

.tooltip-cell {
  font-size: 14px;
  line-height: 18px;
}